import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['player'];

  connect() {
    this.player = null;
  }

  loadMockInterview() {
    if (!this.player) {
      const mockInterviewId = this.data.get('id');

      // Make a POST request to the backend to get the video URL
      fetch(`/mock-interviews/${mockInterviewId}/load_mock_interview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: JSON.stringify({
          company: 'company'
        })
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'ok') {
            // window.location.href = data.mock_interview_url;

            this.element.innerHTML = `<iframe src="${data.mock_interview_url}" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
            this.player = this.element.querySelector('iframe');

          } else {
            console.error('Something Happened!!');
          }
        })
        .catch((error) => {
          console.error('Error fetching video URL:', error);
        });
    }
  }
}