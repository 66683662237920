import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['player'];

  connect() {
    this.player = null;
  }

  loadVideo() {
    if (!this.player) {
      const postId = this.data.get('postId');

      // Make a POST request to the backend to get the video URL
      fetch(`/videos/${postId}/load_video`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'ok') {

            this.element.innerHTML = `<iframe src="${data.embed_url}" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
            this.player = this.element.querySelector('iframe');

          } else {
            alert('You are not authorized to view this video.');
          }
        })
        .catch((error) => {
          console.error('Error fetching video URL:', error);
        });
    }
  }
}