/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails

require("@rails/activestorage").start()
import "@rails/actiontext"

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

// Jumpstart Pro & other Functionality
import "./src/**/*"
import "flowbite/dist/flowbite.turbo.js"

// chartkick
import "chartkick/chart.js"

require("local-time").start()

// Start Rails UJS
Rails.start()

// Need to place in js posts controller
// Function to handle turbo:submit-end event
function handleTurboSubmitEnd(event) {
	const dismissButton = document.getElementById('dismiss-posts-filter-drawer');

	if (dismissButton) {
		dismissButton.click();
	}
}

// Function to initialize on turbo:load event
function initializeOnTurboLoad() {
	if (typeof st !== 'undefined') {
		st.initialize();
	}
	var jobProfileDetails = document.getElementById('job-profile-details');
	if (jobProfileDetails) {
		// Add the 'hidden' class to hide the element
		jobProfileDetails.classList.add('hidden');
	}

}

// Add event listeners
document.addEventListener("turbo:submit-end", handleTurboSubmitEnd);
document.addEventListener("turbo:load", initializeOnTurboLoad);




document.addEventListener('turbo:load', () => {
	// Handle click on job profile link
	document.querySelectorAll('.job-profile-link').forEach(link => {
		link.addEventListener('turbo:click', () => {

			document.addEventListener('turbo:before-fetch-request', () => {
				showLoadingPlaceholder();
			}, { once: true }); // Ensure the event listener is executed only once

			document.querySelectorAll('.job-profile-list-item').forEach(item => {
				item.classList.remove('md:border-2');
			});

			// Add border to the clicked item
			const listItem = link.closest('.job-profile-list-item');
			listItem.classList.add('md:border-2');

			document.querySelector('.job-profile-list').classList.add('hidden');
			document.getElementById('job-profile-details').classList.remove('hidden');

			window.scrollTo({
				top: 0
			});

		});
	});

	// Handle click on close button using event delegation
	document.addEventListener('click', (event) => {
		if (event.target && event.target.id === 'close-details') {
			document.querySelector('.job-profile-list').classList.remove('hidden');
			document.getElementById('job-profile-details').classList.add('hidden');
		}
	});
});


function showLoadingPlaceholder() {
	const loadingPlaceholderBig = `
	<div role="status" class="job-details-loading max-w-lg animate-pulse loading px-5 md:px-8 py-12">
		<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-5"></div>
		<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
		<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-5"></div>
		<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
		<span class="sr-only">Loading...</span>
	</div>
	`;

	const loadingPlaceholderSmall = `
	<div role="status" class="job-details-loading max-w-lg animate-pulse loading">
		<div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
		<div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 "></div>\
		<span class="sr-only">Loading...</span>
	</div>
	`;
	document.querySelectorAll('.job-profile-additional-details').forEach(details => {
		details.innerHTML = loadingPlaceholderBig;
		details.classList.remove('hidden');
	});
	document.querySelectorAll('.job-profile-header-details').forEach(details => {
		details.innerHTML = loadingPlaceholderSmall;
		details.classList.remove('hidden');
	});
}