import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  connect() {

    let settings = {};

    let allow_create = {
      persist: false,
      createOnBlur: true,
      create: true
    }

    let ajax_search = {
      valueField: 'id',
      labelField: 'title',
      searchField: ['title'],
      create: false,
      load: this.fetchUsers.bind(this),
      render: {
        option: this.renderOption.bind(this),
        item: this.renderItem.bind(this)
      }
    }

    if (this.element.dataset.create) {
      settings = { ...settings, ...allow_create }
    }

    if (this.element.dataset.search) {
      const url = this.element.dataset.url;
      if (url) {
        ajax_search.load = this.fetchUsers.bind(this, url); // Bind the URL to the fetchUsers method
      }
      settings = { ...settings, ...ajax_search }
    }

    this.select = new TomSelect(this.element, settings)
  }

  fetchUsers(url, query, callback) {
    if (!query) {
      return callback([]);
    }

    fetch(`${url}?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => console.error('Error fetching users:', error));
  }

  renderOption(user, escape) {
    return `<div>${escape(user.title)}</div>`;
  }

  renderItem(user, escape) {
    return `<div>${escape(user.title)}</div>`;
  }

  disconnect() {
    this.select.destroy();
  }
}
